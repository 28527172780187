.notFoundContainer {
  text-align: center;
  margin-top: 100px;
}

.notFoundContainer h1 {
  font-size: 48px;
  color: #333;
}

.notFoundContainer p {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
}

.notFoundContainer a {
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
}
